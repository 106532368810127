'use strict';

var config = require('./config.js');
var logger = require('./logger.js');

function setKeyValue(key, value) {
	if (!key) {
		logger.warn('Admiral: Failed to set value - key not set.');
		return;
	}

	if (window.admiral === undefined) {
		logger.warn('Admiral: Failed to set value - admiral was undefined.');
		return;
	}

	window.admiral('targeting', 'set', key, value);
	logger.info(`Admiral: Set value {${key}, ${value}}`);
}

function init() {
	setKeyValue('templateName', config.data.page.templateName);
	setKeyValue('isAffiliate', config.data.page.isAffiliate.toString());
	window.admiral('targeting', 'ready'); // Allows admiral to make it's targeting calls now that we have set our targeting
}

module.exports = {
	init: init
};
