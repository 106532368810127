'use strict';

var analytics = require('./analytics');

function init() {
	const trackingParams = getTrackingParams();

	if (!trackingParams) {
		return
	}

	const $productCards = $('.product-card');
	const $atAGlancePanels = $('.at-a-glance-panel');
	const $productCtas = $('.product-cta');

	$productCards.each(function () {
		updateTrackingLinks($(this), trackingParams);
	});

	$atAGlancePanels.each(function () {
		updateTrackingLinks($(this), trackingParams);
	});

	$productCtas.each(function () {
		updateTrackingLinks($(this), trackingParams);
	});

	let event = {
		event: 'AffiliateLinksUpdated'
	};

	analytics.pushToDataLayer(event);
}

function getTrackingParams() {
	const sessionStorageKey = "ppcTrackingParams";
	let result = sessionStorage.getItem(sessionStorageKey);

	if (result) {
		return JSON.parse(result);
	}

	const queryStringParams = new URLSearchParams(window.location.search);

	let trackingIdKey = null;
	let trackingIdValue = null;

	const campaignIdKey = 'campaignid';
	const adSetIdKey = 'adsetid';
	const adGroupIdKey = 'adgroupid';

	if (queryStringParams.has(campaignIdKey)) {
		trackingIdKey = campaignIdKey;
		trackingIdValue = queryStringParams.get(campaignIdKey);
	} else if (queryStringParams.has(adSetIdKey)) {
		trackingIdKey = adSetIdKey;
		trackingIdValue = queryStringParams.get(adSetIdKey);
	} else if (queryStringParams.has(adGroupIdKey)) {
		trackingIdKey = adGroupIdKey;
		trackingIdValue = queryStringParams.get(adGroupIdKey);
	}

	if (!trackingIdKey || !trackingIdValue) {
		return null;
	}

	const utmMedium = queryStringParams.get('utm_medium');
	const utmSource = queryStringParams.get('utm_source');

	result = {
		trackingIdName: trackingIdKey,
		trackingId: trackingIdValue,
		utmMedium: utmMedium,
		utmSource: utmSource
	};

	sessionStorage.setItem(sessionStorageKey, JSON.stringify(result));

	return result;
}

function updateTrackingLinks($container, trackingParams) {
	const $affiliateLinks = $('a', $container);

	$affiliateLinks.each(function () {
		const $link = $(this);
		const href = $link.attr('href');

		if (!href) {
			return;
		}

		const queryParamsToAppend = {};

		if (href.startsWith('https://www.amazon.co.uk') || href.startsWith('https://www.amazon.com')) {
			queryParamsToAppend['asc_source'] = 'paidtraffic';
			queryParamsToAppend['asc_campaign'] = `${trackingParams.trackingIdName},${trackingParams.trackingId}`;
			queryParamsToAppend['asc_refurl'] = window.location.href;
		}
		else if (href.startsWith('https://redirect.viglink.com')) {
			queryParamsToAppend['cuid'] = `${getValueOrNotSet(trackingParams.utmMedium)}|${getValueOrNotSet(trackingParams.utmSource)}|${getValueOrNotSet(trackingParams.trackingIdName)},${getValueOrNotSet(trackingParams.trackingId)}`;
		}
		else {
			queryParamsToAppend['xcust'] = `${getValueOrNotSet(trackingParams.utmMedium)}|${getValueOrNotSet(trackingParams.utmSource)}|${getValueOrNotSet(trackingParams.trackingIdName)},${getValueOrNotSet(trackingParams.trackingId)}`;
		}

		let linkQueryParams = new URLSearchParams($link[0].search);
		for (let key in queryParamsToAppend) {
			linkQueryParams.set(key, queryParamsToAppend[key]);
		}

		$link[0].search = linkQueryParams;
	});
}

function getValueOrNotSet(value) {
	return value ? value : 'NotSet';
}

module.exports = {
	init: init
};