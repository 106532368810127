'use strict';

require('foundation-offcanvas');
require('foundation-interchange');

require('./utils/jquery-extensions.js');

var $ = require('jquery');

var analytics = require('./utils/analytics.js');
var config = require('./utils/config.js');
var menu = require('./ui/menu.js');
var sticky = require('./ui/sticky.js');

var advert = require('./adverts/advert.js');
var skins = require('./adverts/skins.js');

var heightWatcher = require('./watchers/height-watcher.js');
var screenSizeWatcher = require('./watchers/screen-size-watcher.js');

var sourcepoint = require('./utils/sourcepoint.js');

var permutive = require('./utils/permutive.js');
var affiliateLinks = require('./utils/affiliate-links.js');
var admiralTargeting = require('./utils/admiral-targeting.js');

function initConfig() {
	if (typeof config === 'undefined') {
		window.alert('The tgConfig JSON object must be defined before js loaded.');
	} else {
		console.info('TG config', config);
		analytics.pushToDataLayer(config.data);
	}
}

function loadWatchers() {
	heightWatcher.start();
	screenSizeWatcher.start();
}

function initialiseBasePage() {
	initConfig();

	$(document).foundation();

	loadWatchers();

	menu.init();
	sticky.init();
	sourcepoint.init();
	permutive.init();
	affiliateLinks.init();
	admiralTargeting.init();
}

module.exports = {
	initBasePage: initialiseBasePage,

	init: initialiseBasePage,

	initDfp: advert.init,

	adverts: {
		skins: skins
	}
};